<template>

<div class="heading">

	<span>{{ text }}</span>

</div>
	
</template>

<script>

export default {

	props: ['text']
	
}

</script>

<style scoped>

.heading {
	text-align: center;
	margin-bottom: 30px;
}

.is-mobile .heading {
	margin-bottom: 40px;
}

.heading:before {
	content: '';
	position: absolute;
	background-color: #387DD8;
	height: 1px;
	left: 0px;
	top: 50%;
	width: 100%;
	z-index: 1;
}

.heading span {
	background-color: #fff;
	color: #387DD8;
	z-index: 2;
	white-space: nowrap;
	position: absolute;
	left: 50%;
	font-size: 18px;
	font-weight: 700;
	text-transform: uppercase;
	top: 50%;
	letter-spacing: 1px;
	transform: translate(-50%, -50%);
	padding: 0px 40px;
}

.is-mobile .heading span {
	font-size: 16px;
	padding: 0px 20px;
}

</style>