<template>

<div class="discount" :data-theme="theme" :class="{'is-failed': type}">

	<div class="discount-text">Discount Code</div>

	<div class="discount-input">
	
		<input v-if="!is.succeeded" v-model="code" ref="input" v-on:keypress.enter="onValidateClick" :disabled="is.succeeded" maxlength="32" type="text" placeholder="Enter code here..." />
		<div class="discount-code" v-if="is.succeeded">{{ code }}</div>
		
		<div v-for="(comp, index) in comps" :key="index">
			<div class="discount-input-comp">{{ comp.name }}</div>
		</div>

		<div class="discount-type" v-if="type">{{ $constants.registration.discount.failed[type] }}</div>
		<div class="discount-description" v-if="description">{{ description }}</div>

	</div>

	<app-button v-if="!is.succeeded" :text="text" :disabled="!code" :loading="is.validating" :theme="theme" class="discount-button" v-on:click="onValidateClick" />
	<div class="discount-amount" v-if="is.succeeded && amount">- {{ amount | formatMoney }}</div>

</div>
		
</template>

<script>

export default {

	props: ['intent'],

	data: function() {

		return {
			is: {
				validating: false,
				failed: false,
				succeeded: false
			},
			type: '',
			description: '',
			amount: 0,
			comps: [],
			code: '',
			submittedCode: ''
		}

	},

	computed: {

		text: function() {

			if (this.code !== this.submittedCode) return 'Add'
			else if (this.is.failed) return 'Invalid'
			else if (this.is.succeeded) return 'Added'
			else return 'Add'

		},

		theme: function() {

			if (this.code !== this.submittedCode) return false
			else if (this.is.failed) return 'red'
			else if (this.is.succeeded) return 'green'
			else return false

		}

	},

	methods: {

		onValidateClick: function() {

			if (!this.code) return false

			this.is.failed = false
			this.is.succeeded = false
			this.is.validating = true

			this.submittedCode = this.code

			this.$api.patch('intent', {
				code: this.code,
				intent: this.intent
			}).then(function(json) {

				this.amount = json.amount
				this.comps = json.comps
				this.type = ''
				this.description = ''

				this.$emit('discount', json.amount)

				this.is.succeeded = true
				this.is.validating = false

			}.bind(this), function(json) {

				this.type = json.type
				this.description = json.description

				this.is.failed = true
				this.is.validating = false
				this.$refs.input.focus()

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.discount {
	display: flex;
	font-size: 16px;
	font-weight: 700;
	line-height: 20px;
	border: 2px solid #eee;
	border-radius: 12px;
	margin-bottom: 20px;
	height: 40px;
}

.is-mobile .discount {
	display: flex;
	height: auto;
    flex-direction: column;
}

.discount.is-failed {
	height: auto;
}

.discount[data-theme="red"] {
	border-color: #c55b5b;
}

.discount-code {
	padding: 10px 0px;
}

.discount[data-theme="green"] {
	border-color: #4faa80;
	height: auto;
}

.discount-text {
	padding: 10px;
	flex-shrink: 0;
	width: 300px;
	line-height: 16px;
}

.discount.is-failed .discount-text {
	padding-top: 20px;
}

.is-mobile .discount-text {
	width: auto;
}

.discount-type {
	color: #c55b5b;
	font-size: 14px;
	line-height: 14px;
	margin-top: 10px;
}

.discount-description {
	color: #333;
	font-size: 14px;
	font-weight: 300;
	line-height: 18px;
	margin-top: 2px;
}

.discount-input {
	flex-grow: 1;
	line-height: 16px;
	padding-right: 20px;
	padding-left: 10px;
}

.is-mobile .discount-input {
	padding-right: 10px;
	padding-left: 10px;
}

.discount.is-failed .discount-input {
	padding-bottom: 10px;
}

.discount.is-failed .discount-input input {
	background-color: #efefef;
	border-radius: 4px;
	padding-left: 10px;
	padding-right: 10px;
	margin-top: 10px;
}

.discount-input input {
	width: 100%;
	height: 36px;
	padding: 10px 0px;
	line-height: 16px;
}

.discount-input-comp {
	font-size: 14px;
	font-weight: 400;
}

.discount-button {
	border-radius: 0px 12px 12px 0px!important;
	margin-top: -2px!important;
	flex-shrink: 0;
	margin-right: -2px!important;
	height: 40px;
}

.discount.is-failed .discount-button {
	height: calc(100% + 4px);
	display: flex;
	justify-content: center;
	align-items: center;
}

.is-mobile .discount-button {
	width: calc(100% - 20px)!important;
    border-radius: 10px!important;
    height: auto!important;
    margin: 10px;
}

.discount-amount {
	width: 110px;
	padding-right: 10px;
	line-height: 36px;
}

.is-mobile .discount-amount {
	text-align: right;
	white-space: nowrap;
}

</style>