<template>

<app-page-outer v-if="isValid" class="basket" :class="{'is-expanded': is.expanded, 'is-loading': is.loading}">

	<com-bar :valid="isValid" v-show="!is.loading" v-on:review="onReviewClick" v-on:close="onCancelClick" :expanded="is.expanded" />

	<com-review v-show="!is.loading" v-if="is.expanded" v-on:cancel="onCancelClick" v-on:loading="onLoadingChange" />

	<app-icon class="basket-loading" icon="loading" v-if="is.loading && is.expanded" />
	<div class="basket-loading-text" v-if="is.loading && is.expanded && is.loading !== true">{{ is.loading }}</div>

</app-page-outer>
		
</template>

<script>

import comBar from './basket/Bar'
import comReview from './basket/Review'

export default {

	components: {
		'com-bar': comBar,
		'com-review': comReview
	},

	data: function() {

		return {
			is: {
				expanded: false,
				loading: false
			}
		}

	},

	computed: {

		page: function() {

			return this.$store.getters['register/page']

		},

		isValid: function() {

			if (this.isRegistration) {

				return this.$store.getters['register/badges'].length

			} else if (this.isThirdparty) {

				return this.$store.getters['register/thirdparty'] !== false

			} else {

				return true

			}

		},

		isRegistration: function() {

			return this.page.type === this.$constants.registration.page.type.registration

		},

		isThirdparty: function() {

			return this.page.type === this.$constants.registration.page.type.thirdparty

		}

	},

	methods: {
		
		onLoadingChange: function(value) {

			this.is.loading = value

		},

		onReviewClick: function() {

			this.is.expanded = true

		},

		onCancelClick: function() {

			this.is.expanded = false

		}

	}
	
}

</script>

<style scoped>

.basket {
	position: fixed;
	bottom: 0px;
	left: 0px;
	width: 100%;
	flex-direction: column;
	justify-content: flex-start;
	height: 64px;
	background-color: #fff;
	transition: height 100ms linear;
	z-index: 10;
}

.is-mobile .basket {
	height: 80px;
}

.basket-loading {
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	font-size: 48px;
	color: #ffbe7a;
}

.basket-loading-text {
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, 48px);
	font-size: 20px;
	color: #343434;
	font-weight: 500;
}

.is-mobile .basket-loading-text {
	font-size: 16px;
}

.basket.is-expanded {
	height: calc(100vh - var(--vh-offset, 0px));
}

</style>