<template>

<app-page-outer class="review">

	<app-page-inner class="review-inner">

		<com-heading text="Summary" />

		<template v-if="isRegistration">

			<div class="summary-note">Please ensure e-mails are correct as each badge holder will receive their login credentials to manage all aspects of their convention. <b>FOR THIS REASON PLEASE USE A VALID EMAIL ADDRESS.</b></div>

			<div class="summary-item" v-for="(badge, index) in badges" :key="index">

				<div class="summary-item-person">
					{{ badge.name }}
					<div class="summary-item-subline">{{ badge.email }}</div>
				</div>

				<div class="summary-item-item">
					{{ badge.type }} <template v-if="window.is.mobile">({{ badge.price | formatMoney }})</template>
					<div class="summary-item-subline" v-for="(addon, index) in badge.stacked" :key="index">{{ addon.name }}<template v-if="window.is.mobile">({{ addon.price | formatMoney }})</template></div>
					<div class="summary-item-subline" v-for="(addon, index) in badge.addons" :key="index">{{ addon.name }} x {{ addon.quantity }} <template v-if="window.is.mobile">({{ addon.price | formatMoney }})</template></div>
				</div>

				<div class="summary-item-price">
					+ {{ badge.price | formatMoney }}
					<div class="summary-item-subline" v-for="(addon, index) in badge.stacked" :key="index">+ {{ addon.price | formatMoney }}</div>
					<div class="summary-item-subline" v-for="(addon, index) in badge.addons" :key="index">+ {{ addon.price | formatMoney }}</div>
				</div>

				<div class="summary-item-total">
					= {{ badge.total | formatMoney }}
				</div>

			</div>

		</template>

		<template v-if="isThirdparty">

			<div class="summary-item" v-for="(addon, index) in addons" :key="index">

				<div class="summary-item-item">
					{{ addon.name }} x {{ addon.quantity }} <template v-if="window.is.mobile">({{ addon.price | formatMoney }})</template>
				</div>

				<div class="summary-item-total">
					= {{ addon.price | formatMoney }}
				</div>

			</div>

		</template>

		<com-discount :intent="intentKey" v-on:discount="onDiscount" />

		<div class="summary-item" v-for="addon in defaultAddons" :key="addon.id">

			<div class="summary-item-item">{{ addon.name }} x {{ badges.length }}</div>

			<div class="summary-item-total">= {{ (addon.price * badges.length) | formatMoney }}</div>

		</div>

		<div class="summary-total">

			<div class="summary-item-item">Total</div>

			<div class="summary-item-total">{{ total | formatMoney }}</div>

		</div>

		<com-heading text="Payment"  />

		<div class="review-errors" v-if="errorMessage">{{ errorMessage }}</div>

		<stripe-element-payment v-on:error="onError" v-on:element-ready="onReady" ref="stripe" :pk="pk" :elements-options="options" :confirm-params="params" v-if="options.clientSecret" class="review-stripe" />

		<div class="review-buttons">

			<app-button text="Complete" v-if="!errorMessage" v-on:click="onCompleteClick" />
			<app-button text="Back" theme="plain" v-on:click="onCancelClick" />

		</div>

	</app-page-inner>

</app-page-outer>
		
</template>

<script>

import { StripeElementPayment } from '@vue-stripe/vue-stripe'
import comHeading from './../Heading'
import comDiscount from './review/Discount'

export default {

	components: {
		StripeElementPayment,
		comHeading,
		comDiscount
	},

	data: function() {

		return {
			errorMessage: false,
			pk: process.env.VUE_APP_STRIPE,
			testMode: true,
			intentKey: '',
			discount: 0,
			options: {
				appearance: {},
				clientSecret: false
			},
			params: {
				return_url: ''
			}
		}

	},

	created: function() {

		this.$emit('loading', true)

		this.params.return_url = this.page.url

		if (this.$store.getters['register/status'] === this.$constants.registration.payment.failed || this.$store.getters['register/status'] === this.$constants.registration.payment.failed) {

			this.errorMessage = 'Sorry, a problem was encountered and the payment was unsuccessful. Please try again.'

		}

		this.$api.post('intent', {
			page: this.$store.getters['register/page'].id,
			keys: this.$store.getters['register/intent'],
			type: (this.$store.getters['register/thirdparty']) ? this.$constants.registration.sale.type.thirdparty : this.$constants.registration.sale.type.registration,
			data: {
				badges: this.$store.getters['register/badges'],
				addons: this.$store.getters['register/addons'],
				thirdparty: this.$store.getters['register/thirdparty']
			}
		}).then(function(json) {

			this.intentKey = json.intent
			this.options.clientSecret = json.clientSecret

		}.bind(this), function() {

			this.errorMessage = 'Sorry, there was a problem communicating with the payment gateway. We\'re working hard to resolve it. Please try again later today.'
			this.$emit('loading', false)

		}.bind(this))

	},

	computed: {

		page: function() {

			return this.$store.getters['register/page']

		},

		isRegistration: function() {

			return this.page.type === this.$constants.registration.page.type.registration

		},

		isThirdparty: function() {

			return this.page.type === this.$constants.registration.page.type.thirdparty

		},

		defaultAddons: function() {

			return this.$store.getters['register/page'].addons

		},

		total: function() {

			var total = 0 

			this.$_.each(this.defaultAddons, function(addon) {

				total += addon.price * this.badges.length

			}.bind(this))

			this.$_.each(this.$store.getters['register/badges'], function(badge) {

				this.$_.each(badge.stacked, function(stack) {

					total += stack.price

				})

			}.bind(this))

			this.$_.each(this.$store.getters['register/addons'], function(addon) {

				total += ((addon.variant) ? addon.variant.price : addon.item.price) * addon.quantity

			})

			return total - this.discount

		},

		addons: function() {

			var addons = []

			this.$_.each(this.$store.getters['register/addons'], function(addon) {

				addons.push({
					name: addon.item.name + ((addon.variant) ? ' - ' + addon.variant.name : ''),
					price: ((addon.variant) ? addon.variant.price : addon.item.price) * addon.quantity,
					quantity: addon.quantity
				})

			})

			return addons

		},

		badges: function() {

			var badges = [], item

			this.$_.each(this.$store.getters['register/badges'], function(badge) {

				item = {
					type: badge.badge.name,
					price: badge.badge.price,
					total: badge.badge.price,
					name: badge.name,
					email: badge.email,
					addons: [],
					stacked: []
				}

				this.$_.each(badge.stacked, function(stack) {

					if (stack.id !== badge.badge.id) {

						item.stacked.push({
							name: stack.name,
							price: stack.price,
							quantity: 1
						})

						item.total += stack.price

					}

				})

				this.$_.each(this.$store.getters['register/addons'], function(addon) {

					if (addon.badge === badge.id) {

						item.addons.push({
							name: addon.item.name + ((addon.variant) ? ' - ' + addon.variant.name : ''),
							price: ((addon.variant) ? addon.variant.price : addon.item.price) * addon.quantity,
							quantity: addon.quantity
						})

						item.total += ((addon.variant) ? addon.variant.price : addon.item.price) * addon.quantity

					}

				})

				badges.push(item)

			}.bind(this))

			return badges

		}

	},

	methods: {

		onDiscount: function(value) {

			this.discount = value

		},

		onReady: function() {

			this.$emit('loading', false)

		},

		onError: function(e) {

			this.errorMessage = e.message

			this.$emit('loading', false)

		},

		onCompleteClick: function() {

			this.$emit('loading', 'Processing payment...')

			this.errorMessage = false

			this.$refs.stripe.submit()

		},

		onCancelClick: function() {

			this.$emit('cancel')

		}

	}

}

</script>

<style scoped>

.review {
	width: 100%;
	background-color: #fff;
	height: calc(100vh - var(--vh-offset, 0px) - 64px);
	overflow: auto;
}

.is-mobile .review {
	height: calc(100vh - var(--vh-offset, 0px) - 80px);
}

.review-inner {
	display: flex;
	padding: 40px 20px;
	flex-direction: column;
}

.is-mobile .review-inner {
	padding: 40px 10px;
}

.summary-note {
	background-color: #387dd8;
	padding: 10px;
	border-radius: 12px;
	margin-bottom: 20px;
	font-size: 14px;
	text-align: center;
	color: #fff;
}

.is-mobile .summary-note {
	margin-bottom: 10px;
}

.summary-total {
	display: flex;
	font-size: 20px;
	font-weight: 700;
	line-height: 20px;
	border: 2px solid #eee;
	padding: 10px;
	border-radius: 12px;
	margin-bottom: 40px;
}

.is-mobile .summary-total {
	font-size: 16px;
	flex-direction: column;
}

.summary-item {
	display: flex;
	font-size: 16px;
	font-weight: 700;
	line-height: 20px;
	border: 2px solid #eee;
	padding: 10px;
	border-radius: 12px;
	margin-bottom: 20px;
}

.is-mobile .summary-item {
	margin-bottom: 10px;
	flex-direction: column;
}

.summary-item-person {
	width: 300px;
	flex-shrink: 0;
}

.is-mobile .summary-item-person {
	width: 100%;
	margin-bottom: 10px;
}

.summary-item-subline {
	font-size: 14px;
	font-weight: 400;
}

.summary-item-item {
	flex-grow: 1;
}

.is-mobile .summary-item-item {
	margin-bottom: 10px;
	width: 100%;
}

.summary-item-price {
	width: 100px;
	flex-shrink: 0;
	font-weight: 400;
	font-size: 14px;
}

.is-mobile .summary-item-price {
	display: none;
}

.summary-item-total {
	width: 100px;
	flex-shrink: 0;
}

.is-mobile .summary-item-total {
	width: 100%;
	text-align: right;
}

.review-stripe {
	width: 100%;
}

.review-buttons {
	display: flex;
	justify-content: center;
	margin-top: 20px;
	padding-bottom: 20px;
}

.review-buttons >>> .button {
	margin: 0px 5px;
}

.review-errors {
	background-color: #c55b5b;
	color: #fff;
	font-size: 16px;
	line-height: 20px;
	padding: 18px 20px;
	text-align: center;
	border-radius: 4px;
	margin-bottom: 20px;
}

.review >>> #stripe-payment-element-errors {
	display: none;
}

</style>